import * as styles from '../exchangeDetail.module.css'
import React, { useEffect, useState, useCallback } from 'react'
import loadable from '@loadable/component'
import MetaData from '../../components/MetaData'
import { Container, Row, Col, Spinner, Button } from 'react-bootstrap'
import { Section, Title, Text, Box, Input } from '../../components/Core'
import PageWrapper from '../../components/PageWrapper'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import Link from '../../components/Link'
import Highcharts from 'highcharts/highstock'
import Indicators from 'highcharts/indicators/indicators-all.js'
import FullScreen from 'highcharts/modules/full-screen.js'
import Exporting from 'highcharts/modules/exporting.js'
import StockTools from 'highcharts/modules/stock-tools.js'
import * as currenciesActions from '../../store/actions/currenciesActions'
import * as newsActions from '../../store/actions/newsActions'
import * as userActions from '../../store/actions/userActions'
import EditIcon from '../../assets/image/svg/ic_pencil.inline.svg'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import { debounce } from 'lodash'
import { suggestEdit } from '../../store/actions/userActions'

const CurrMarketsTable = loadable(() =>
  import('../../components/Table/CurrMarketsTable')
)
const Select = loadable(() => import('../../components/Table/RangeSelector'))
const HighchartsReact = loadable(() => import('highcharts-react-official'))
const NewsDetail = loadable(() => import('../../sections/news/NewsDetail'))

const BoxShadow = styled(Box)`
  border-radius: 10px;
  overflow: hidden;
  padding: 15px 15px 8px 15px;
  box-shadow: 0 42px 54px rgba(65, 65, 101, 0.16);
`

const NoDataCard = ({ color = 'primary', title, children, ...rest }) => {
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  const { t } = useTranslation( ['tables'], { useSuspense: false });
  return (
    <div className={styles.noDataCard}>
      <Text color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
        ⚠ {t('noMatchCurrencies')}
      </Text>
    </div>
  )
}

const CurrencyDetail = ({ location }) => {
  let locationArr = location.pathname.split('/')
  let locationCurrSlug = locationArr[locationArr.length - 2]
  let newToken =
    /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie) !== null
      ? /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie)[1]
      : ''

  const dispatch = useDispatch()
  const selectedCurr = useSelector(state => state.currencies.currency)
  const realData = useSelector(state => state.currencies.ohlc)
  const chartQuoteCurr = useSelector(state => state.currencies.ohlcQuoteCurr)
  const userExchRate = useSelector(state => state.user.exchRate)
  const userCurrency = useSelector(state => state.user.selectedCurr)
  const currSign = useSelector(state => state.user.currSign)
  const btcExchRate = useSelector(state => state.currencies.btcExchRate)
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const markets = useSelector(state => state.currencies.currMarkets)
  const userHighChRange = useSelector(state => state.user.highChartsRange)
  const [defaultMarket, setDefaultMarket] = useState(markets[0])
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  const { t, i18n, ready } = useTranslation( ['cryptocurrencies', 'tables'] , { useSuspense: false });

  // SUGGEST EDIT CODE
  const [openTextArea, setOpenTextArea] = useState(false)
  const [inputValue, setInputValue] = useState(null)
  const request = debounce(value => {
    setInputValue(value)
  }, 1000)
  const debouceRequest = useCallback(value => request(value), [request])
  const onChange = e => {
    debouceRequest(e.target.value)
  }

  const editDescOptions = [
    { label: ready && `${t(`missingData`)}`, value: 0 },
    { label: ready && `${t(`incorrectData`)}`, value: 1 },
    { label: ready && `${t(`other`)}`, value: 2 },
  ]
  const [editType, setEditType] = useState(0)

  const editCurrDesc = () => {
    if (inputValue !== null) {
      dispatch(suggestEdit(newToken, editType, location.href, inputValue))
      setOpenTextArea(false)
      setInputValue(null)
    } else {
      alert('please enter a valid message!')
    }
  }

  //

  useEffect(() => {
    dispatch(currenciesActions.fetchBtcExchRate(userCurrency))
  }, [dispatch, userCurrency])

  useEffect(() => {
    setDefaultMarket(markets[0])
  }, [dispatch, markets])

  useEffect(() => {
    if (locationCurrSlug !== null) {
      dispatch(currenciesActions.fetchCurrOHLC(locationCurrSlug, userCurrency))
      dispatch(currenciesActions.fetchCurrencyDetail(locationCurrSlug))
      dispatch(newsActions.fetchNewsDetail(locationCurrSlug))
      const interval = setInterval(() => {
        dispatch(
          currenciesActions.fetchCurrOHLC(locationCurrSlug, userCurrency)
        )
        dispatch(currenciesActions.fetchCurrencyDetail(locationCurrSlug))
        dispatch(currenciesActions.fetchBtcExchRate(userCurrency))
      }, 90000)
      return () => clearInterval(interval)
    }
  }, [dispatch, locationCurrSlug, userCurrency])

  useEffect(() => {
    if (realData.length < 1 && typeof defaultMarket !== 'undefined') {
      dispatch(
        currenciesActions.fetchCurrOHLC(
          locationCurrSlug,
          defaultMarket.quoteCurrencyCode
        )
      )
      const interval = setInterval(() => {
        dispatch(
          currenciesActions.fetchCurrOHLC(
            locationCurrSlug,
            defaultMarket.quoteCurrencyCode
          )
        )
      }, 90000)
      return () => clearInterval(interval)
    }
  }, [dispatch, locationCurrSlug, defaultMarket, realData.length])

  const [windowWidth, setWindowWidth] = useState()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setWindowWidth(window.innerWidth)
      window.onresize = () => {
        setWindowWidth(window.innerWidth)
      }
    }
  }, [])

  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCompleted(true)
    }, 5000)
    return () => clearTimeout(timer)
  }, [])

  let volume24 =
    typeof selectedCurr !== 'undefined'
      ? selectedCurr.btcVolume24Hour * btcExchRate
      : 0
  let volume1 =
    typeof selectedCurr !== 'undefined'
      ? selectedCurr.btcVolume1Hour * btcExchRate
      : 0
  let price =
    typeof selectedCurr !== 'undefined'
      ? selectedCurr.btcPrice * btcExchRate
      : 0
  let mktCap = ''
  let mktCapString
  let mktCapNumber

  if (
    typeof selectedCurr !== 'undefined' &&
    typeof selectedCurr.marketCap !== 'undefined'
  ) {
    mktCap = selectedCurr.marketCap.replace('}', '')
    mktCapString = mktCap.split(':')
    mktCapNumber = parseInt(mktCapString[1])
  }

  if (isNaN(mktCapNumber)) {
    mktCapNumber = 0
  }

  let mktCapValue = userExchRate ? mktCapNumber * userExchRate : mktCapNumber

  let circulatingSupply =
    typeof selectedCurr !== 'undefined' &&
    typeof selectedCurr.availableSupply !== 'undefined'
      ? parseInt(selectedCurr.availableSupply)
      : 0

  if (isNaN(circulatingSupply)) {
    circulatingSupply = 0
  }

  let maxSupply =
    typeof selectedCurr !== 'undefined' &&
    typeof selectedCurr.availableSupply !== 'undefined'
      ? parseInt(selectedCurr.miningTotalCirculation)
      : 0

  if (isNaN(maxSupply)) {
    maxSupply = 0
  }

  const metaData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages
          }
        }
      }
    `
  )
  const langs = metaData.site.siteMetadata.languages
  let i18nLang
  let lang

  if (typeof i18n.language !== 'undefined') {
    i18nLang = i18n.language.includes('-')
      ? i18n.language.split('-')[0]
      : i18n.language
  }

  lang =
    typeof langs.find(lang => lang === i18nLang) !== 'undefined'
      ? langs.find(lang => lang === i18nLang)
      : 'en'

  // IF check to avoid Highcharts error on gatsby build
  if (typeof window !== `undefined`) {
    Indicators(Highcharts)
    FullScreen(Highcharts)
    StockTools(Highcharts)
    Exporting(Highcharts)
  }

  if (realData.length > 1) {
    var ohlc = [],
      volume = [],
      dataLength = realData.length,
      // groupingUnits = [
      //   [
      //     "week", // unit name
      //     [1] // allowed multiples
      //   ],
      //   [
      //       "day", // unit name
      //       [1] // allowed multiples
      //   ],
      //   [
      //       "month", // unit name
      //       [1, 2, 3, 4, 5, 6] // allowed multiples
      //   ]
      // ],
      i = 0

    for (i; i < dataLength; i += 1) {
      let timestampDate = new Date(realData[i].endTime).getTime()
      ohlc.push([
        timestampDate, // the date
        realData[i].open, // open
        realData[i].high, // high
        realData[i].low, // low
        realData[i].close, // close
      ])

      volume.push([
        timestampDate, // the date
        realData[i].volume, // the volume
      ])
    }
  }

  const [range, setRange] = useState(null)

  useEffect(() => {
    if (range !== null) {
      dispatch(userActions.setHighChRange(range))
    }
  }, [dispatch, range])

  const options = {
    rangeSelector: {
      buttons: [
        {
          type: 'month',
          count: 1,
          text: '1m',
          events: {
            click: function () {
              setRange(0)
            },
          },
        },
        {
          type: 'month',
          count: 3,
          text: '3m',
          events: {
            click: function () {
              setRange(1)
            },
          },
        },
        {
          type: 'month',
          count: 6,
          text: '6m',
          events: {
            click: function () {
              setRange(2)
            },
          },
        },
        {
          type: 'ytd',
          text: 'YTD',
          events: {
            click: function () {
              setRange(3)
            },
          },
        },
        {
          type: 'year',
          count: 1,
          text: '1y',
          events: {
            click: function () {
              setRange(4)
            },
          },
        },
        {
          type: 'all',
          text: 'All',
          events: {
            click: function () {
              setRange(5)
            },
          },
        },
      ],
      enabled: true,
      allButtonsEnabled: true,
      selected: userHighChRange !== null ? userHighChRange : 4,
      buttonTheme: {
        // styles for the buttons
        fill: 'none',
        stroke: 'none',
        'stroke-width': 0,
        r: 4,
        style: {
          color: userDarkMode ? '#CCCCCC' : '#191B20',
          fontWeight: 'bold',
          fontSize: '13px',
        },
        states: {
          hover: {
            fill: userDarkMode ? 'black' : '#CCCCCC',
          },
          select: {
            fill: '#3377C6',
            style: {
              color: 'white',
            },
          },
          // disabled: { ... }
        },
      },

      inputBoxBorderColor: 'transparent',
      inputStyle: {
        color: '#3377C6',
      },
    },

    chart: {
      height: windowWidth > 991 ? (9 / 16) * 100 + '%' : 600, // 16:9 ratio
      backgroundColor: userDarkMode ? '#191B20' : 'white',
      style: { fontFamily: 'CircularStd' },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ['downloadPNG', 'downloadSVG', 'printChart'],
        },
        // customButton: {
        //     text: 'Launch app',
        //     onclick: function () {
        //       if (typeof window !== 'undefined') {
        //         window.open(`${process.env.V2_URL}/markets/${defaultMarket.exchCode}/${defaultMarket.baseCurrencyCode}/${defaultMarket.quoteCurrencyCode}`,'_blank');
        //       }
        //     },
        // },
      },
    },

    title: {
      text:
        realData.length > 1 &&
        typeof selectedCurr !== 'undefined' &&
        typeof chartQuoteCurr !== 'undefined'
          ? selectedCurr.code + '/' + chartQuoteCurr.toUpperCase()
          : typeof defaultMarket !== 'undefined' &&
            typeof selectedCurr !== 'undefined'
          ? selectedCurr.code + '/' + defaultMarket.quoteCurrencyCode
          : '',
      align: 'left',
      style: {
        color: userDarkMode ? '#FAFAFA' : 'black',
        'font-size': '21px',
        'font-weight': '900',
      },
    },
    xAxis: {
      lineColor: userDarkMode ? '#303030' : '#CACACA',
    },

    yAxis: [
      {
        gridLineColor: userDarkMode ? '#303030' : '#CACACA',
        labels: {
          align: 'right',
          x: -3,
        },
        title: {
          text: 'OHLC',
        },
        height: '80%',
        lineWidth: 2,
        lineColor: userDarkMode ? '#303030' : '#CACACA',

        resize: {
          enabled: true,
        },
      },
      {
        gridLineColor: userDarkMode ? '#303030' : '#CACACA',
        labels: {
          align: 'right',
          x: -3,
        },
        title: {
          text: 'Volume',
        },
        top: '65%',
        height: '35%',
        offset: 0,
        lineWidth: 2,
        lineColor: userDarkMode ? '#303030' : '#CACACA',
      },
    ],

    tooltip: {
      split: true,
    },

    navigator: {
      outlineColor: userDarkMode ? '#303030' : '#CACACA',
      xAxis: {
        gridLineColor: userDarkMode ? '#303030' : '#CACACA',
      },
      series: {
        lineWidth: 0,
        fillOpacity: 0,
      },
    },

    scrollbar: {
      barBackgroundColor: '#CACACA',
      barBorderRadius: 0,
      barBorderWidth: 0,
      buttonBackgroundColor: userDarkMode ? '#303030' : '#CACACA',
      buttonBorderWidth: 0,
      buttonBorderRadius: 2,
      buttonArrowColor: userDarkMode ? 'white' : 'black',
      trackBackgroundColor: 'none',
      trackBorderWidth: 1,
      trackBorderRadius: 2,
      trackBorderColor: userDarkMode ? '#303030' : '#CACACA',
    },

    series: [
      {
        type: 'candlestick',
        name:
          realData.length > 1 &&
          typeof selectedCurr !== 'undefined' &&
          typeof chartQuoteCurr !== 'undefined'
            ? selectedCurr.code + '/' + chartQuoteCurr.toUpperCase()
            : typeof defaultMarket !== 'undefined' &&
              typeof selectedCurr !== 'undefined'
            ? selectedCurr.code + '/' + defaultMarket.quoteCurrencyCode
            : '',
        data: ohlc,
        color: '#FF4242',
        upColor: '#50AF7B',
        lineColor: '#FF4242',
        upLineColor: '#50AF7B',
        // dataGrouping: {
        //   units: groupingUnits
        // }
      },
      {
        type: 'column',
        name: 'Volume',
        data: volume,
        yAxis: 1,
        color: '#3377C6',
        // dataGrouping: {
        //   units: groupingUnits
        // }
      },
    ],
  }

  if (
    completed &&
    selectedCurr !== false &&
    typeof selectedCurr !== 'undefined'
  ) {
    return ready && (
      <>
        <MetaData page="currSlug" param={{code: selectedCurr.code ,currName: selectedCurr.name, locationCurrSlug}} />
        <PageWrapper headerDark footerDark>
          <Section bg={userDarkMode ? 'black' : 'bg'} pb={[4, null, '40px', 5]}>
            <Container>
              <Row>
                <Col className="d-flex justify-content-between my-2" lg="12">
                  <Link
                    to={`/${lang}/cryptocurrencies/${selectedCurr.prevSlug.toLowerCase()}/`}
                  >
                    <span
                      style={{
                        cursor: 'pointer',
                        color: userDarkMode ? '#FAFAFA' : 'black',
                      }}
                    >
                      {selectedCurr.prevSlugName
                        ? '← ' + selectedCurr.prevSlugName.toUpperCase()
                        : ''}
                    </span>
                  </Link>
                  <Link
                    to={`/${lang}/cryptocurrencies/${selectedCurr.nextSlug.toLowerCase()}/`}
                  >
                    <span
                      style={{
                        cursor: 'pointer',
                        color: userDarkMode ? '#FAFAFA' : 'black',
                      }}
                    >
                      {selectedCurr.nextSlugName
                        ? selectedCurr.nextSlugName.toUpperCase() + ' →'
                        : ''}
                    </span>
                  </Link>
                </Col>
              </Row>
              <Row className='mt-5'>
                <Col lg="8">
                  <Box className="d-flex align-items-center" mb={4}>
                    <img
                      style={{ width: '40px', height: '40px' }}
                      src={
                        process.env.WWW_URL +
                        `/assets/img/currency/${selectedCurr.code}.png`
                      }
                      alt={`${selectedCurr.code} Logo`}
                      onError={e => {
                        e.target.onerror = null
                        e.target.src =
                          process.env.WWW_URL + `/assets/img/currency/empty.png`
                      }}
                    />
                    <Box className="d-flex align-items-baseline mt-1">
                      <Title
                        color={userDarkMode ? '#FAFAFA' : 'dark'}
                        className="ml-3 mb-0"
                        variant="hero-smaller"
                      >
                        {selectedCurr.name}
                      </Title>
                      <Text
                        color={userDarkMode ? 'lightShade' : 'darkShade'}
                        className="ml-2"
                      >
                        {selectedCurr.code}
                      </Text>
                    </Box>
                  </Box>
                </Col>
                <Col lg="4" className='text-right'>
                  <Text
                    color={userDarkMode ? 'lightShade' : 'darkShade'}
                    variant="small"
                  >
                    {t('tables.price')}
                  </Text>
                  <Text
                    color={userDarkMode ? '#FAFAFA' : 'black'}
                    className="ml-2"
                    variant="strong"
                  >
                    {currSign ? currSign : '$'}{' '}
                    {price.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </Text>
                </Col>
              </Row>

              {completed && realData.length > 1 && windowWidth > 991 ? (
                <Row className="mb-5">
                  <Col lg="8" xl="9">
                    <BoxShadow
                      style={{
                        backgroundColor: userDarkMode ? '#191B20' : 'white',
                      }}
                      mb={4}
                    >
                      <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={options}
                      />
                    </BoxShadow>
                    <Text
                      color={userDarkMode ? 'lightShade' : 'darkShade'}
                      variant="small"
                      mb={4}
                    >
                      {t(`${selectedCurr.code}`).includes(
                        'currenciesPage'
                      )
                        ? ''
                        : t(`${selectedCurr.code}`)}
                    </Text>
                    {userSessionExpired === false ? (
                      <div>
                        <EditIcon />
                        <Button
                          onClick={() => setOpenTextArea(true)}
                          style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                          }}
                        >
                          <Text color="#3377C6" variant="small">
                            {t(`suggestEdit`)}
                          </Text>
                        </Button>
                      </div>
                    ) : null}

                    {openTextArea === true ? (
                      <Row>
                        <Select
                          className="mb-3 mt-3"
                          isSearchable={false}
                          options={editDescOptions}
                          borderRadius={0}
                          defaultValue={editDescOptions[1]}
                          onChange={option => setEditType(option.value)}
                          components={{ IndicatorSeparator: () => null }}
                          userDarkMode={userDarkMode}
                        />
                        <Input
                          onChange={e => onChange(e)}
                          placeholder="Enter your suggestion..."
                        />
                        <Button
                          className="mt-3"
                          onClick={() => editCurrDesc(inputValue)}
                          style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                          }}
                        >
                          <Text color="#3377C6" variant="small">
                            {t(`send`)}
                          </Text>
                        </Button>
                      </Row>
                    ) : null}
                  </Col>
                  <Col lg="4" xl="3" xs="12">
                    <Row className="text-right">
                      <Col lg="12" xs="6">
                        {defaultMarket ? (
                          <a
                            className={styles.aTagButton}
                            style={{ marginBottom: 32 }}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${process.env.LS_APP_URL}/markets/${defaultMarket.exchCode}/${defaultMarket.baseCurrencyCode}/${defaultMarket.quoteCurrencyCode}`}
                          >
                            {t('launch')}
                          </a>
                        ) : (
                          <div></div>
                        )}

                        {/* <Text color={userDarkMode ? "lightShade" : "darkShade"} variant="small">{t('tables.price')}</Text> */}
                        {/* <Text color={userDarkMode ? "#FAFAFA" : "black"} mb={4}>{currSign ? currSign : '$'} {price.toLocaleString(undefined,{ minimumFractionDigits: 2 })}</Text> */}
                        <Text
                          color={userDarkMode ? 'lightShade' : 'darkShade'}
                          variant="small"
                        >
                          {t('tables.volume24')}
                        </Text>
                        <Text color={userDarkMode ? '#FAFAFA' : 'black'} mb={4}>
                          {currSign ? currSign : '$'}{' '}
                          {volume24.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </Text>
                        <Text
                          color={userDarkMode ? 'lightShade' : 'darkShade'}
                          variant="small"
                        >
                          {t('tables.volume1')}
                        </Text>
                        <Text color={userDarkMode ? '#FAFAFA' : 'black'} mb={4}>
                          {currSign ? currSign : '$'}{' '}
                          {volume1.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </Text>
                        <Text
                          color={userDarkMode ? 'lightShade' : 'darkShade'}
                          variant="small"
                        >
                          {t('marketCap')}
                        </Text>
                        <Text color={userDarkMode ? '#FAFAFA' : 'black'} mb={4}>
                          {mktCapValue > 1
                            ? currSign +
                              mktCapValue.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })
                            : '-'}
                        </Text>
                      </Col>
                      <Col lg="12" xs="6">
                        <Text
                          color={userDarkMode ? 'lightShade' : 'darkShade'}
                          variant="small"
                        >
                          {t('circulating')}
                        </Text>
                        <Text color={userDarkMode ? '#FAFAFA' : 'black'} mb={4}>
                          {circulatingSupply > 1
                            ? circulatingSupply.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              }) +
                              ' ' +
                              selectedCurr.code
                            : '-'}
                        </Text>
                        <Text
                          color={userDarkMode ? 'lightShade' : 'darkShade'}
                          variant="small"
                        >
                          {t('max')}
                        </Text>
                        <Text color={userDarkMode ? '#FAFAFA' : 'black'} mb={4}>
                          {maxSupply > 1
                            ? maxSupply.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              }) +
                              ' ' +
                              selectedCurr.code
                            : '-'}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : completed && realData.length > 1 && windowWidth < 991 ? (
                <div style={{ textAlign: 'start' }} className="mb-5">
                  <Col>
                    <BoxShadow
                      style={{
                        backgroundColor: userDarkMode ? 'black' : 'white',
                      }}
                      mb={4}
                    >
                      <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={options}
                      />
                    </BoxShadow>
                    <Text
                      color={userDarkMode ? 'lightShade' : 'darkShade'}
                      variant="small"
                      mb={4}
                    >
                      {t(`${selectedCurr.code}`).includes(
                        'currenciesPage'
                      )
                        ? ''
                        : t(`${selectedCurr.code}`)}
                    </Text>
                  </Col>
                  <Col>
                    <div style={{ textAlign: 'start' }}>
                      <Col>
                        {defaultMarket ? (
                          <a
                            className={styles.aTagButton}
                            style={{ marginBottom: 32 }}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${process.env.LS_APP_URL}/markets/${defaultMarket.exchCode}/${defaultMarket.baseCurrencyCode}/${defaultMarket.quoteCurrencyCode}`}
                          >
                            {t('launch')}
                          </a>
                        ) : (
                          <div></div>
                        )}

                        <Text
                          color={userDarkMode ? 'lightShade' : 'darkShade'}
                          variant="small"
                        >
                          {t('price')}
                        </Text>
                        <Text color={userDarkMode ? '#FAFAFA' : 'black'} mb={4}>
                          {currSign ? currSign : '$'}{' '}
                          {price.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </Text>
                        <Text
                          color={userDarkMode ? 'lightShade' : 'darkShade'}
                          variant="small"
                        >
                          {t('volume24')}
                        </Text>
                        <Text color={userDarkMode ? '#FAFAFA' : 'black'} mb={4}>
                          {currSign ? currSign : '$'}{' '}
                          {volume24.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </Text>
                        <Text
                          color={userDarkMode ? 'lightShade' : 'darkShade'}
                          variant="small"
                        >
                          {t('volume1')}
                        </Text>
                        <Text color={userDarkMode ? '#FAFAFA' : 'black'} mb={4}>
                          {currSign ? currSign : '$'}{' '}
                          {volume1.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </Text>
                        <Text
                          color={userDarkMode ? 'lightShade' : 'darkShade'}
                          variant="small"
                        >
                          {t('marketCap')}
                        </Text>
                        <Text color={userDarkMode ? '#FAFAFA' : 'black'} mb={4}>
                          {mktCapValue > 1
                            ? currSign +
                              mktCapValue.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })
                            : '-'}
                        </Text>
                      </Col>
                      <Col lg="12" xs="6">
                        <Text
                          color={userDarkMode ? 'lightShade' : 'darkShade'}
                          variant="small"
                        >
                          {t('circulating')}
                        </Text>
                        <Text color={userDarkMode ? '#FAFAFA' : 'black'} mb={4}>
                          {circulatingSupply > 1
                            ? circulatingSupply.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              }) +
                              ' ' +
                              selectedCurr.code
                            : '-'}
                        </Text>
                        <Text
                          color={userDarkMode ? 'lightShade' : 'darkShade'}
                          variant="small"
                        >
                          {t('max')}
                        </Text>
                        <Text color={userDarkMode ? '#FAFAFA' : 'black'} mb={4}>
                          {maxSupply > 1
                            ? maxSupply.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              }) +
                              ' ' +
                              selectedCurr.code
                            : '-'}
                        </Text>
                      </Col>
                    </div>
                  </Col>
                </div>
              ) : completed && realData.length < 1 ? (
                <Col lg="8" xl="9">
                  <NoDataCard
                    style={{
                      backgroundColor: userDarkMode ? '#191B20' : 'light',
                    }}
                  />
                </Col>
              ) : (
                <Row>
                  <Spinner
                    variant={userDarkMode ? 'light' : 'dark'}
                    animation="border"
                  />
                </Row>
              )}
            </Container>
          </Section>
          <Section bg={userDarkMode ? 'black' : 'bg'} py={[0, null, null, 0]}>
            <Container>
              <CurrMarketsTable
                currCode={selectedCurr ? selectedCurr.code : ''}
              />
            </Container>
          </Section>
          <NewsDetail />
        </PageWrapper>
      </>
    )
  } else
    return (
      <Section>
        <Container>
          <Row
            style={{ minHeight: '300px' }}
            className="d-flex align-items-center"
          >
            {typeof selectedCurr === 'undefined' ? (
              <Col>
                <NoDataCard
                  style={{
                    backgroundColor: userDarkMode ? '#191B20' : 'light',
                  }}
                />
                <div className="mt-5">
                  <Link
                    aria-label="back to currencies"
                    to={`/${lang}/cryptocurrencies`}
                  >
                    <Text>Back to currencies!</Text>
                  </Link>
                </div>
              </Col>
            ) : (
              <Col className="text-center">
                <Spinner
                  variant={userDarkMode ? 'light' : 'dark'}
                  animation="border"
                />
              </Col>
            )}
          </Row>
        </Container>
      </Section>
    )
}

export default CurrencyDetail
